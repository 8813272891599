<template>
  <div class="news">
    <h1>News</h1>
    <div class="card">
      <div class="title">FinalChoice正式リリース v1.0</div>
      <div class="subhead">2021/09/24</div>
      <div class="card-content">
        Final Choice v1.0 リリースノート<br>
        ついに待望のFinalChoice正式リリースとなります。<br>
        <br>
        コロナ禍ではありますが、ぜひ落ち着いたら使ってみてください。<br>
        <br>
        <router-link to="/contact">ご意見・ご感想はこちら</router-link>
      </div>
    </div>
    <div class="card">
      <div class="title">リリース v0.8</div>
      <div class="subhead">2021/08/27</div>
      <div class="card-content">
        Final Choice v0.8 リリースノート<br>
        様々な箇所を微修正しました！<br>
        <br>
        どこが修正されたか探してみてください！<br>
        <br>
        宣伝もせずに見ているマニアックなそこのあなた！<br>
        ぜひ生のご意見をお聞かせください。<br>
        <router-link to="/contact">ご意見・ご感想はこちら</router-link>
      </div>
    </div>
    <div class="card">
      <div class="title">リリース v0.5</div>
      <div class="subhead">2021/07/30</div>
      <div class="card-content">
        Final Choice アプリ(β)がリリースされました。<br>
        現在正式リリースまでのテスト・修正を行っています。<br>
        <br>
        正式リリースまで暫くお待ち下さい。<br>
        <br>
        宣伝もせずに見ているマニアックなそこのあなた！<br>
        ぜひ生のご意見をお聞かせください。<br>
        <router-link to="/contact">ご意見・ご感想はこちら</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  data() {
    return {
      counter: 0
    }
  },
  mounted() {
    console.log("mounted")
  }
}
</script>

<style scoped>
  .card {
    /* width: 450px; */
    max-width: 100%;
    border: #555555 solid 3px;
    border-radius: 10px;
    padding: 10px;
    margin: 8px 0;
  }

  .subhead {
    opacity: .6;
  }



  .card-content img {
    max-width: 100%;
  }
</style>
